<template>
  <div class="vlt-category-container">
    <div class="container-content">
      <div class="title-div">
        <div>
          <h5 class="content-title">{{ title }}</h5>
        </div>
        <div>
          <img
            class="close"
            src="@/assets/icons/close.svg"
            alt="vlite-logo"
            loading="lazy"
            @click="actClose"
          />
        </div>
      </div>
      <!-- <h5 class="content-title">{{ title }}</h5> -->

      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12 vlt-contents vlt-display"
        v-if="!contents || !contents.length"
      >
        <p class="no-data">{{ noData }}</p>
      </div>
      <!-- </div> -->
    </div>

    <list-contents v-if="contents" 
    :passContents="contents" 
    :contentType="contentType" 
    :categoryTitle="category" 
    :orientation="orientation"
    >
    </list-contents>

    <app-loader
      class="loader col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12"
      v-show="showLoading"
    ></app-loader>
    <router-view />
  </div>
</template>
<script>
import "firebase/database";
import FirebaseActions from "@/mixins/FirebaseActions";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";

export default {
  name: "Watchlist",
  data() {
    return {
      contents: null,
      contentType: undefined,
      category: "Shows",
      showCategoryFilter: false,
      noData: "",
      title: "Watchlist",
      watchlistResponse: "",
      orientation: "LANDSCAPE",
      showLoading: false,
      fromPage: "watchlist"
    };
  },
  computed: {
    ...mapGetters(["appMenu"]),
  },
  watch: {
    $route(to, from) {
      this.contentType = this.$route.params.contentType;
      this.fetchWatchlist();
      // this.getCategory(this.contentType);
    },
    contents(newval){
      if(!newval||(newval.length===0)){
        this.noData = "Watchlist is empty";
      }
      else this.noData="";
    }
  },
  methods: {
    ...mapActions(["actGetContent"]),
    ...mapMutations(['commitContentData']),
    actClose() {
      this.$router.push({ name: "Home" });
    },
    updateContents(contentdata){
      if(contentdata.status===false){
        this.contents=this.contents.filter(content=>{
          content.hideShow=false;
          return content.contentid!==contentdata.contentid
          });
      }
      else if(contentdata.status){
        this.fetchWatchlist();
      }
    },
    fetchWatchlist(params) {
      if(params && params.frompage==='watchlist' && params.contentid){
         this.commitContentData({
                  contentid: params.contentid,
                  data: {
                    inwatchlist:false
                  }
              });
      }
      this.watchlistResponse = [];
      this.showLoading = true;

      let contentPromise = this.fetchAllWatchlistContentFromFireBase({
        watchlist: true,
      }).then((response) => {
        if (response) {
          this.showLoading = false;
          this.watchlistResponse = [...this.watchlistResponse, ...response];
        }
      });

      let seriesPromise = this.fetchAllWatchlistSeriesFromFireBase({
        watchlist: true,
      }).then((result) => {
        if (result) {
          result.forEach((content) => (content.nodetype = "series"));
          this.watchlistResponse = [...this.watchlistResponse, ...result];
        }
      });
      Promise.all([contentPromise, seriesPromise])
        .then(() => {
          if (this.watchlistResponse.length === 0) {
            this.contents = null;
            this.noData = "Watchlist is empty";
            return;
          }
          if (this.watchlistResponse.length > 0) {
            this.watchlistResponse.sort((first, second) =>
              first.watchlisttimestamp > second.watchlisttimestamp ? -1 : 1
            );

            let eventPromises = this.watchlistResponse
              .filter((content) => content.contenttype === "EVENT"|| content.contenttype==="LIVE")
              .map((content) => this.actGetContent(content.contentid));

            return Promise.all(eventPromises);
          }
        })
        .then((response) => {
          console.log(response);
          response &&
            Array.isArray(response) &&
            response.forEach((content) => {
              let watchcontent = this.watchlistResponse.find(
                (watchcontent) => watchcontent.contentid === content.contentid
              );
              if(watchcontent){
                    this.$set(watchcontent, "fromdate", content.fromdate);
                    this.$set(watchcontent,"todate",content.todate);
                    this.$set(
                    watchcontent,
                    "premieringstatus",
                    content.premieringstatus
                    );
                    this.$set(watchcontent,'language',content.language);
               }
            });
          this.contents = this.watchlistResponse;
        })
        .catch((err) => {
          console.log(err);
          this.contents = null;
          this.noData = "Couldn't get Watchlist. Please try again.";
        });
    }
  },
  created() {
    // this.contentType = this.$route.params.contentType;
    this.fetchWatchlist();
    // this.getCategory(this.contentType);
    // EventBus.$on("catogoryChange", this.closeContentDetail);
    EventBus.$on("deleteContent", this.fetchWatchlist);
    EventBus.$on("firebase_content_status",this.updateContents)
  },
  components: {
    "list-contents": () =>
      import(
        /* webpackChunkName: "listContents" */ "@/components/Shared/templates/listContents.vue"
      ),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      ),
  },
  mixins: [appMixins, FirebaseActions],
};
</script>
<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_hovereffects.scss";
@import "~sass/modules/_mediaQueries.scss";

.row {
  margin: 2% 0;
}
.title-div {
  display: flex;
  justify-content: space-between;
  .close {
    cursor: pointer;
    @include closeIconHover;
  }
}
.content-row {
  flex-wrap: wrap;
  display: flex;
  padding-top: 1%;
}
.vlt-category-container {
  // margin: 3em 0 10em;
  max-width: var(--max-width);
  margin: auto;
  .container-content {
    margin: 48px 0 0 0;
  }
}
.vlt-contents {
  margin-bottom: 20px;
}
.vlt-display {
  text-align: center;
}
.vlt-contents-details {
  width: 100%;
  margin-bottom: 20px;
  margin-top: -15px;
}
.content-title {
  text-align: left;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  font-size: 1.75rem;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: $font-clr-white;
  margin-bottom: 20px;
}
.filter-menu {
  ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin-right: 2rem;
    padding: 0.2% 1.3%;
    color: $font-clr-white-1;
    cursor: pointer;
    p {
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      font-size: 1rem;
    }
  }
  .active {
    color: $font-clr-white;
    background: $clr-bg-gray-dark-1 0% 0% no-repeat padding-box;
    border-radius: 6px;
  }
}
.no-data {
  font-family: $font-family;
  font-weight: $font-weight-medium;
  color: $font-clr-gray;
  font-size: 1.2rem;
  line-height: 32px;
}
.detail-div {
  width: 95%;
}

.down-arrow {
  position: relative;
  top: -6px;
  height: 15px;
  span {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid $theme-primary;
  }
}
@include breakpoint(max1280) {
  .vlt-category-container {
    width: 80vw;
  }
}
</style>